<template>
  <v-dialog v-model="critdialog" key="id_time" max-width="1200px" height="500px">
   <v-card>
     <v-card-title>Stock por almacen</v-card-title>
      <v-radio-group v-model="agg" label="Seleccione como agrupar los datos" row>
        <br>
        <v-radio label="Días" value="day" />
        <v-radio label="Semanas" value="week" />
      </v-radio-group>
       <apexchart ref="almchart" type="bar" height="200"  :options="chartOptions" :series="series" />
   </v-card>
  </v-dialog>
</template>

<script>
export default {
data () {
    return {
    agg:"day",
    critdialog: false,
    id_time: 0,
    chartOptions: {
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            stacked: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return Intl.NumberFormat("es-CL", {
              style: "currency",
              currency: "CLP"
            }).format(value)
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: [{
          title: {
            text: '$ valor'
          },
          labels: {
            formatter: function (value) {
              return Intl.NumberFormat("es-CL", {
                style: "currency",
                currency: "CLP"
              }).format(value)
            }
          }
        }]
      },
      series: [{
        data: [],
      }],
    }
},
methods: {
    open: function (id){
        this.id_time=id
        this.critdialog=true
    }
}
}
</script>

<style>

</style>