<template>
<div>
  <!-- <v-btn v-show="dash" app to="/dashboard" color="red darken-4" dark tile>
        Dashboard
  </v-btn> -->
  <v-select v-model="empresa" label="Seleccione empresa" :items="empresas" @change="grafdata1()"></v-select>
  <apexchart ref="empchart" height=200 :options="chartOptions" :series="series" @dataPointSelection="almchart()" />
  <v-row>
  <v-col cols="12" md="6">
  <apexchart ref="almchart" type="bar" height="200"  :options="chartAlm" :series="seriesAlm" @dataPointSelection="almweeks()"/></v-col>
  <v-col cols="12" md="6">
  <apexchart ref="critchart" type="bar" height="200"  :options="chartCrit" :series="seriesCrit" @dataPointSelection="critweeks()" /></v-col>
  </v-row>
  <critDialog ref="weekchartcrit"/>
  <almDialog ref="weekchartalm" />
</div>
</template>

<script>
import CodeDataService from '../../services/CodeDataService';
import critDlg from './WeekCritChart.vue'
import almDlg from './WeekAlmChart.vue'
export default {
  name: "dashboard",
  components: {
    critDialog : critDlg,
    almDialog: almDlg
  },
  data() {
    return {
      dash: true,
      empresa: 'AGROINDUSTRIAL EL PAICO S.A.',
      empresas: [
        'AGRICOLA ARIZTIA LTDA',
        'AGRICOLA LLUTA S.A.',
        'AGRICOLA ROBLEDAL LTDA.',
        'AGRICOLA TARAPACA S.A.',
        'AGROINDUSTRIAL ARICA S.A.',
        'AGROINDUSTRIAL EL PAICO S.A.',
        'ARIZTIA COMERCIAL LTDA',
        'INDUSTRIAL OCHAGAVIA LTDA.',
        'SERVICIOS DE MANTENCION LTDA.',
        'TECNOLOGIA Y ALIMENTOS LTDA.-'

      ],
      chartOptions: {
        chart: {
          type: 'bar',
          toolbar:{
            show: false,  
        },
        },
        plotOptions: {
          bar: {
            stacked: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return Intl.NumberFormat("es-CL", {
              style: "currency",
              currency: "CLP"
            }).format(value)
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: [{
          title: {
            text: '$ valor'
          },
          labels: {
            formatter: function (value) {
              return Intl.NumberFormat("es-CL", {
                style: "currency",
                currency: "CLP"
              }).format(value)
            }
          }
        }]
      },
      series: [{
        data: [],
      }],
      chartAlm: {
        chart: {
          type: 'bar',
          toolbar:{
            show: false,  
        },
        },
        plotOptions: {
          bar: {
            stacked: true,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return Intl.NumberFormat("es-CL", {
              style: "currency",
              currency: "CLP"
            }).format(value)
          },
          style: {
            colors: ['#000']
          }
        },
        noData:{
          text:"Para ver detalles seleccione una de las columnas en el gráfico anterior"
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (value) {
              return Intl.NumberFormat("es-CL", {
                style: "currency",
                currency: "CLP"
              }).format(value)
            }
          }
        }, //agreggate $sum TOTAL $group ALMACEN   [ARRAY1]
        yaxis: [{
          title: {
            text: 'Almacenes'
          }
        }]
      },
      seriesAlm: [{
        data: [] //agreggate $sum TOTAL $group ALMACEN   [ARRAY1]
      }],
      chartCrit: {
        chart: {
          type: 'bar',
          toolbar:{
            show: false,  
        },
        },
        plotOptions: {
          bar: {
            stacked: true,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return Intl.NumberFormat("es-CL", {
              style: "currency",
              currency: "CLP"
            }).format(value)
          },
          style: {
            colors: ['#000']
          }
        },
        noData:{
          text:"Para ver detalles seleccione una de las columnas en el gráfico anterior"
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (value) {
              return Intl.NumberFormat("es-CL", {
                style: "currency",
                currency: "CLP"
              }).format(value)
            }
          }
        }, //agreggate $sum TOTAL $group ALMACEN   [ARRAY1]
        yaxis: [{
          title: {
            text: 'Criticidad'
          }
        }]
      },
      seriesCrit: [{
        data: [] //agreggate $sum TOTAL $group ALMACEN   [ARRAY1]
      }]
    }
  },
  methods: {
    grafdata1() {
      let data = {}
      data['emp'] = this.empresa
      CodeDataService.dashdata(data)
        .then((response) => {
          this.series[0] = {
            data: response.data[0].montos
          }
          this.chartOptions.xaxis = [{
            categories: response.data[0].categoria
          }]
          this.$refs.empchart.updateOptions({
            xaxis: {
              categories: this.chartOptions.xaxis[0].categories
            }
          })
          this.$refs.empchart.updateSeries([{
            data: this.series[0].data
          }])
        })
    },
    almchart() {
      let dp = this.$refs.empchart.chart.w.globals.selectedDataPoints[0][0]
      let sday = this.chartOptions.xaxis[0].categories[dp]
      let data = {}
      data['dma'] = sday
      data['emp'] = this.empresa
      CodeDataService.dashalmdata(data)
        .then((response) => {
           this.seriesAlm[0] = {
            data: response.data[0].montos
          }
          this.chartAlm.xaxis = [{
            categories: response.data[0].categoria
          }]
          this.$refs.almchart.updateOptions({
            xaxis: {
              categories: this.chartAlm.xaxis[0].categories
            }
          })
          this.$refs.almchart.updateSeries([{
            data: this.seriesAlm[0].data
          }])
        })
      CodeDataService.dashcritdata(data)
       .then((response) => {
           this.seriesCrit[0] = {
            data: response.data[0].montos
          }
          this.chartCrit.xaxis = [{
            categories: response.data[0].categoria
          }]
          this.$refs.critchart.updateOptions({
            xaxis: {
              categories: this.chartCrit.xaxis[0].categories
            }
          })
          this.$refs.critchart.updateSeries([{
            data: this.seriesCrit[0].data
          }])
        })
    },
    critweeks () {
      let dp = this.$refs.critchart.chart.w.globals.selectedDataPoints[0][0]
      if(dp !== undefined) {
      this.$refs.weekchartcrit.open(new Date())
      }
    },
    almweeks () {
      let dp = this.$refs.almchart.chart.w.globals.selectedDataPoints[0][0]
      if(dp !== undefined) {
      this.$refs.weekchartalm.open(new Date())
      }
    }
  },
  mounted() {
    this.grafdata1()
  }

}
</script>

